import { ResponsiveBehavior } from "layoutSystems/common/utils/constants";
import { FILL_WIDGET_MIN_WIDTH } from "constants/minWidthConstants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import ContainerWidget from "widgets/ContainerWidget/widget";
import type { WidgetDefaultProps } from "../WidgetProvider/constants";
import type { AutocompletionDefinitions } from "WidgetProvider/constants";
import type { SetterConfig } from "entities/AppTheming";

/**
 * Please refer to renderAppsmithCanvas in CanvasFactory to see current version of How CanvasWidget is rendered.
 */
import styled from "styled-components";

const CanvasLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: left center;
  transition:
    0.4s transform,
    0.4s opacity;
  transform: perspective(2000px) rotateY(0deg);
`;

class CanvasWidget extends ContainerWidget {
  static type = "CANVAS_WIDGET";

  static getConfig() {
    return {
      name: "Canvas",
      hideCard: true,
      eagerRender: true,
    };
  }

  static getDefaults(): WidgetDefaultProps {
    // let hack: any = {};
    // if (this.props.isCustomLayout) {
    //   hack = {
    //     canExtend: false,
    //   };
    // }
    return {
      rows: 0,
      columns: 0,
      widgetName: "Canvas",
      version: 1,
      detachFromLayout: true,
      flexLayers: [],
      responsiveBehavior: ResponsiveBehavior.Fill,
      minWidth: FILL_WIDGET_MIN_WIDTH,
      // ...hack,
    };
  }

  static getPropertyPaneConfig() {
    return [];
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {};
  }

  static getSetterConfig(): SetterConfig | null {
    return null;
  }

  // renderAsLayerDropTarget() {
  //   return (
  //     <CanvasLayer className={this.props.noPad ? "" : "fold-layer"}>
  //       {this.renderAsDropTarget()}
  //     </CanvasLayer>
  //   );
  // }

  // getCanvasView() {
  //   if (!this.props.dropDisabled) {
  //     if (this.props.isCanvasLayer) {
  //       return this.renderAsLayerDropTarget();
  //     }
  //     return this.renderAsDropTarget();
  //   }
  //   return this.getPageView();
  // }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }
  // TODO Find a way to enforce this, (dont let it be set)
  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }
}

export default CanvasWidget;
