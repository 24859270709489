import React, { useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { updateApplicationLayout, updateApplication, } from "@appsmith/actions/applicationActions";
import type {
  AppLayoutConfig,
  SupportedLayouts,
} from "reducers/entityReducers/pageListReducer";
import {
  getCurrentApplicationId,
  getCurrentApplicationLayout,
  getCustomSize,
} from "selectors/editorSelectors";
import { Icon, SegmentedControl, Tooltip } from "design-system";
import { InputNumber } from "antd";
import { debounce } from "lodash";

const StyledSegmentedControl = styled(SegmentedControl)`
  > .ads-v2-segmented-control__segments-container {
    flex: 1 1 0%;
  }
`;

interface AppsmithLayoutConfigOption {
  name: string;
  type: SupportedLayouts;
  icon: string;
}

export const AppsmithDefaultLayout: AppLayoutConfig = {
  type: "FLUID",
};

const AppsmithLayouts: AppsmithLayoutConfigOption[] = [
  {
    name: "自适应宽度",
    type: "FLUID",
    icon: "fluid",
  },
  {
    name: "桌面宽度",
    type: "DESKTOP",
    icon: "desktop",
  },
  {
    name: "平板宽度（横向）",
    type: "TABLET_LARGE",
    icon: "tabletLandscape",
  },
  {
    name: "平板宽度（竖向）",
    type: "TABLET",
    icon: "tablet",
  },
  {
    name: "手机宽度",
    type: "MOBILE",
    icon: "mobile",
  },
  {
    name: "自定义",
    type: "CUSTOM",
    icon: "edit",
  },
];

const options = AppsmithLayouts.map((layout, index) => ({
  label: (
    <Tooltip
      content={layout.name}
      key={layout.name}
      mouseEnterDelay={0}
      placement={
        index === AppsmithLayouts.length - 1 ? "bottomRight" : "bottom"
      }
    >
      <Icon name={layout.icon} size="md" />
    </Tooltip>
  ),
  value: layout.type,
}));

/**
 * OldName: MainContainerLayoutControl
 */
export function MainContainerWidthToggles() {
  const dispatch = useDispatch();
  const appId = useSelector(getCurrentApplicationId);
  const appLayout = useSelector(getCurrentApplicationLayout);
  const customSize = useSelector(getCustomSize);
  const isCustom = appLayout?.type === "CUSTOM";
  /**
   * updates the app layout
   *
   * @param layoutConfig
   */
  const updateAppLayout = useCallback(
    (type: string) => {
      dispatch(
        updateApplicationLayout(appId || "", {
          appLayout: {
            // @ts-expect-error: Type error
            type,
          },
        }),
      );
    },
    [dispatch, appLayout],
  );

  const updateCustomSize = useCallback(
    (type) =>
      debounce((value: any) => {
        if (value) {
          const size = {
            ...customSize,
            [type]: value,
          };
          dispatch(
            updateApplication(appId, {
              customSize: `${size.width}_${size.height}`,
            }),
          );
        }
      }, 500),
    [dispatch, customSize],
  );

  return (
    <div className="pb-6 space-y-2 t--layout-control-wrapper">
      <StyledSegmentedControl
        defaultValue={appLayout.type}
        onChange={updateAppLayout}
        options={options}
      />
      {isCustom ? (
        <div className="flex">
          <div className="flex flex-auto items-center">
            <span className="mr-2">宽度</span>
            <InputNumber
              className="flex-1"
              min={500}
              max={100000}
              step={10}
              value={customSize.width}
              onChange={updateCustomSize("width")}
            />
          </div>
          <div className="flex flex-auto items-center">
            <span className="mx-2">高度</span>
            <InputNumber
              className="flex-1"
              min={500}
              max={50000}
              step={10}
              value={customSize.height}
              onChange={updateCustomSize("height")}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
