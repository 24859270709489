import React from "react";
import type { ReactNode } from "react";
import styled from "styled-components";
import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";
import type { WidgetProps } from "widgets/BaseWidget";
import { getCanvasClassName } from "utils/generators";
import { Popup } from "@taroify/core";
import type { PopupProps } from "@taroify/core/popup/popup";
import { getCanvasWidth } from "selectors/editorSelectors";
import { useSelector } from "react-redux";

import { ClickContentToOpenPropPane } from "utils/hooks/useClickToSelectWidget";
import { WidgetNameLayer } from "layoutSystems/common/widgetName/WidgetNameLayer";
import { EVAL_ERROR_PATH } from "utils/DynamicBindingUtils";
import { get } from "lodash";

const Container = styled(Popup)<
  {
    height?: number;
  } & PopupProps
>`
  height: ${(props) => props.height}px;
  max-height: 200px;
  min-height: 80px;
  overflow: visible;
  width: 450px;
  left: unset;
  background: #fff;
  z-index: 1009;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export interface BottomBarComponentProps extends WidgetProps {
  childrenProps: WidgetProps[];
  className?: string;
  widgetId: string;
  height: number;
}

/* eslint-disable react/display-name */
export function BottomBarComponent(props: BottomBarComponentProps) {
  const mainCanvasWidth = useSelector(getCanvasWidth);

  const renderChildWidget = (childWidgetData: WidgetProps): ReactNode => {
    const childWidget = { ...childWidgetData };
    childWidget.key = props.widgetId;
    childWidget.parentId = props.widgetId;
    childWidget.shouldScrollContents = false;
    childWidget.canExtend = false;
    childWidget.bottomRow = props.height;
    childWidget.containerStyle = "none";
    childWidget.minHeight = props.height;
    childWidget.rightColumn = mainCanvasWidth;
    return renderAppsmithCanvas(childWidget as WidgetProps);
  };

  const getChildren = (): ReactNode => {
    if (props.childrenProps && props.childrenProps.length > 0) {
      const children = props.childrenProps.filter(Boolean);
      return children.length > 0 && children.map(renderChildWidget);
    }
  };
  const editorWrapper = (content: ReactNode) => {
    if (props.renderMode === "CANVAS") {
      return (
        <WidgetNameLayer
          componentWidth={mainCanvasWidth}
          detachFromLayout
          disablePropertyPane={false}
          evalErrorsObj={get(props, EVAL_ERROR_PATH, {})}
          parentId={props.parentId}
          showControls
          topRow={props.topRow}
          type={props.type}
          widgetId={props.widgetId}
          widgetName={props.widgetName}
        >
          <ClickContentToOpenPropPane widgetId={props.widgetId}>
            {content}
          </ClickContentToOpenPropPane>
        </WidgetNameLayer>
      );
    }
    return content;
  };
  const children = editorWrapper(getChildren());

  return (
    <Container
      defaultOpen
      placement="bottom"
      height={props.height}
      duration={0}
    >
      <Popup.Backdrop
        open={false}
        closeable={false}
        style={{ left: "unset", right: "unset", width: "450px" }}
      />
      <Content className={`${getCanvasClassName()} ${props.className}`}>
        {children}
      </Content>
    </Container>
  );
}

export default BottomBarComponent;
