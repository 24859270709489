import React from "react";
import type { BaseWidgetProps } from "widgets/BaseWidgetHOC/withBaseWidgetHOC";

const TaroDetachedWrapper = ({
  type,
  detachFromLayout,
  children,
}: BaseWidgetProps) => {
  if (!detachFromLayout) {
    return children;
  }
  if (type === "TARO_BOTTOM_BAR_WIDGET" || type === "TARO_POPUP_WIDGET") {
    return <div style={{ height: 0 }}>{children}</div>;
  }
  if (type === "TARO_LOADING_WIDGET") {
    return (
      <div style={{ zIndex: 10000, position: "relative" }}>{children}</div>
    );
  }
  return children;
};

export default TaroDetachedWrapper;
