import React from "react";
import BaseWidget, { WidgetProps, WidgetState } from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import LoadingComponent from "../component";

class MLoadingWidget extends BaseWidget<MLoadingWidgetProps, WidgetState> {
  static type = "TARO_LOADING_WIDGET";

  static getConfig() {
    return {
      name: "加载遮罩",
      searchTags: ["loading"],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      isMobile: true,
      tags: [WIDGET_TAGS.DISPLAY],
    };
  }

  static getDefaults() {
    return {
      widgetName: "loading",
      rows: 8,
      columns: 16,
      version: 1,
      detachFromLayout: true,
      showLoading: false,
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc": "加载遮罩",
      "!url": "https://taroify.gitee.io/taroify.com/introduce/",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "showLoading",
            label: "显示加载中",
            controlType: "SWITCH",
            helpText: "用于绑定动作的运行状态",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "loadingText",
            label: "加载文案",
            controlType: "INPUT_TEXT",
            placeholderText: "加载中...",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  getWidgetView() {
    return <LoadingComponent {...this.props} />;
  }
}

export interface MLoadingWidgetProps extends WidgetProps {
  showLoading?: boolean;
  loadingText?: string;
}

export default MLoadingWidget;
