import React, { lazy, Suspense } from "react";
import * as _ from "lodash";
import Skeleton from "components/utils/Skeleton";
import { contentConfig, styleConfig } from "./propertyConfig";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { retryPromise } from "utils/AppsmithUtils";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import BaseWidget, { WidgetProps, WidgetState } from "widgets/BaseWidget";
import { Colors } from "constants/Colors";
import type { AllChartData, ChartType } from "../constants";
import type { Stylesheet } from "entities/AppTheming";
import { generateReactKey } from "widgets/WidgetUtils";
import { DEFAUTL_CHART } from "../constants";

const defaultKey = generateReactKey();

const EchartComponent = lazy(async () =>
  retryPromise(
    async () =>
      import(
        /* webpackPrefetch: true, webpackChunkName: "charts" */ "../component"
      ),
  ),
);

class EchartWidget extends BaseWidget<EchartWidgetProps, WidgetState> {
  static type = "ECHART_WIDGET";

  static getConfig() {
    return {
      name: "Echarts图表",
      tags: [WIDGET_TAGS.DISPLAY, WIDGET_TAGS.SUGGESTED_WIDGETS],
      iconSVG: IconSVG,
      needsMeta: true,
      searchTags: ["graph", "echart", "chart", "visualisations"],
    };
  }

  static getFeatures() {
    return {
      floatLayout: {
        active: true,
        defaultValue: false,
        sectionIndex: 1,
      },
    };
  }

  static getDefaults() {
    return {
      widgetName: "Echarts",
      mycustom: 2,
      rows: 32,
      columns: 24,
      version: 1,
      chartData: {
        [defaultKey]: {
          type: DEFAUTL_CHART.type,
          seriesName: DEFAUTL_CHART.chartName,
          data: DEFAUTL_CHART.data,
        },
      },
      chartType: DEFAUTL_CHART.chartType,
      chartName: DEFAUTL_CHART.name,
      xAxisName: DEFAUTL_CHART.xAxisName,
      yAxisName: DEFAUTL_CHART.yAxisName,
      allowScroll: false,
      animateLoading: true,
      customEchartConfig: {
        title: {
          text: "基础折线图",
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: "line",
          },
        ],
      },
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc": "ECharts图表解决方案",
      "!url": "https://echarts.apache.org/examples/zh/index.html",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return styleConfig;
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      fontFamily: "{{appsmith.theme.fontFamily.appFont}}",
    };
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedDataItem: undefined,
      instance: null,
    };
  }

  onDataPointClick = (selectedDataItem: any) => {
    this.props.updateWidgetMetaProperty(
      "selectedDataItem",
      _.omit(selectedDataItem, "event"),
      {
        triggerPropertyName: "onDataPointClick",
        dynamicString: this.props.onDataPointClick,
        event: {
          type: EventType.ON_DATA_POINT_CLICK,
        },
      },
    );
  };

  listenerCallback = (eventName: string, callbackData: any) => {
    _.mapValues(this.props.listener, (item: any) => {
      if (item.seriesName === eventName && item.handler) {
        super.executeAction({
          triggerPropertyName: eventName,
          dynamicString: item.handler,
          event: {
            type: EventType.ON_ECHART_EVENT,
          },
          callbackData: [_.omit(callbackData, "event")],
        });
      }
    });
  };

  getEchartInstance = (instance: any) => {
    const params = {
      name: "instance",
      type: "custom",
      id: instance.id,
    };
    this.props.updateWidgetMetaProperty("instance", params);
  };

  getWidgetView() {
    return (
      <Suspense fallback={<Skeleton />}>
        <EchartComponent
          allowScroll={this.props.allowScroll}
          borderRadius={this.props.borderRadius}
          boxShadow={this.props.boxShadow}
          chartData={this.props.chartData}
          chartName={this.props.chartName}
          chartType={this.props.chartType}
          customEchartConfig={this.props.customEchartConfig}
          fontFamily={this.props.fontFamily ?? "Nunito Sans"}
          isLoading={this.props.isLoading}
          isVisible={this.props.isVisible}
          key={this.props.widgetId}
          labelOrientation={this.props.labelOrientation}
          primaryColor={this.props.accentColor ?? Colors.ROYAL_BLUE_2}
          backgroundColor={this.props.backgroundColor}
          setAdaptiveYMin={this.props.setAdaptiveYMin}
          widgetId={this.props.widgetId}
          xAxisName={this.props.xAxisName}
          yAxisName={this.props.yAxisName}
          onDataPointClick={this.onDataPointClick}
          onInstance={this.getEchartInstance}
          registerMapName={this.props.registerMapName}
          registerMapJsonUrl={this.props.registerMapJsonUrl}
          listener={this.props.listener}
          onListener={this.listenerCallback}
        />
      </Suspense>
    );
  }
}

export interface EchartWidgetProps extends WidgetProps {
  chartType: ChartType;
  chartData: AllChartData;
  customEchartConfig: any;
  xAxisName: string;
  yAxisName: string;
  chartName: string;
  isVisible?: boolean;
  allowScroll: boolean;
  borderRadius: string;
  boxShadow?: string;
  accentColor?: string;
  fontFamily?: string;
  backgroundColor?: string;
  onDataPointClick?: string;
  registerMapName?: string;
  registerMapJsonUrl?: string;
  listener?: string;
  onListener?: void;
}

export default EchartWidget;
