import React from "react";
import BaseWidget, { WidgetProps, WidgetState } from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import MarqueeComponent, { MarqueeComponentProps } from "../component";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";

class MarqueeWidget extends BaseWidget<MarqueeWidgetProps, WidgetState> {
  static type = "MARQUEE_WIDGET";

  static getConfig() {
    return {
      name: "跑马灯",
      iconSVG: IconSVG,
      searchTags: ["marquee", "滚动", "scroll", "文本", "text"],
      needsMeta: false,
      isCanvas: false,
      tags: [WIDGET_TAGS.DISPLAY],
    };
  }

  static getDefaults() {
    return {
      widgetName: "Marquee",
      rows: 8,
      columns: 48,
      version: 1,
      text: "欢迎光临",
      play: true,
      direction: "left",
      speed: 100,
      delay: 0,
      loop: 0,
      gradient: true,
      gradientWidth: 200,
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc": "跑马灯组件",
      "!url": "https://taroify.gitee.io/taroify.com/introduce/",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "text",
            helpText: "设置跑马灯文字内容",
            label: "文字内容",
            controlType: "INPUT_TEXT",
            placeholderText: "跑马灯文字内容",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: { limitLineBreaks: true },
            },
          },
          {
            propertyName: "play",
            helpText: "是否运行跑马灯",
            label: "运行",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "direction",
            helpText: "跑马灯运行方向",
            label: "方向",
            controlType: "ICON_TABS",
            fullWidth: true,
            options: [
              {
                startIcon: "arrow-left-line",
                value: "left",
              },
              {
                startIcon: "arrow-right-line",
                value: "right",
              },
            ],
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
          {
            propertyName: "speed",
            helpText: "跑马灯运行速度",
            label: "速度（px/秒）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "delay",
            helpText: "跑马灯在加载后延迟多久开始运行",
            label: "延迟（秒）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "loop",
            helpText: "跑马灯循环次数，设置为 0 表示无限循环",
            label: "循环次数（0 表示无限循环）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
          {
            propertyName: "pauseOnHover",
            helpText: "鼠标接触跑马灯时停止运行跑马灯",
            label: "接触时停止",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "pauseOnClick",
            helpText: "鼠标点击跑马灯时停止运行跑马灯",
            label: "点击时停止",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "isVisible",
            helpText: "控制组件的显示/隐藏",
            label: "是否显示",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "animateLoading",
            label: "加载时显示动画",
            controlType: "SWITCH",
            helpText: "组件依赖的数据加载时显示加载动画",
            defaultValue: true,
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "动作",
        children: [
          {
            helpText: "每一次循环结束后",
            propertyName: "onCycleComplete",
            label: "onCycleComplete",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
          {
            helpText: "所有循环结束后",
            propertyName: "onFinish",
            label: "onFinish",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
            hidden: (props: MarqueeWidgetProps) => props.loop == 0,
            dependencies: ["loop"],
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "文字样式",
        children: [
          {
            propertyName: "textColor",
            label: "文字颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "backgroundColor",
            label: "背景颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "fontFamily",
            label: "字体",
            controlType: "DROP_DOWN",
            options: [
              {
                label: "系统默认字体",
                value: "System Default",
              },
              {
                label: "Nunito Sans",
                value: "Nunito Sans",
              },
              {
                label: "Poppins",
                value: "Poppins",
              },
              {
                label: "Inter",
                value: "Inter",
              },
              {
                label: "Montserrat",
                value: "Montserrat",
              },
              {
                label: "Noto Sans",
                value: "Noto Sans",
              },
              {
                label: "Open Sans",
                value: "Open Sans",
              },
              {
                label: "Roboto",
                value: "Roboto",
              },
              {
                label: "Rubik",
                value: "Rubik",
              },
              {
                label: "Ubuntu",
                value: "Ubuntu",
              },
              {
                label: "digital",
                value: "digital",
              },
              {
                label: "clock",
                value: "clock",
              },
            ],
            defaultValue: "System Default",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "fontSize",
            label: "字体大小",
            controlType: "DROP_DOWN",
            defaultValue: "1rem",
            options: [
              {
                label: "S",
                value: "0.875rem",
                subText: "0.875rem",
              },
              {
                label: "M",
                value: "1rem",
                subText: "1rem",
              },
              {
                label: "L",
                value: "1.25rem",
                subText: "1.25rem",
              },
              {
                label: "XL",
                value: "1.875rem",
                subText: "1.875rem",
              },
              {
                label: "XXL",
                value: "3rem",
                subText: "3rem",
              },
              {
                label: "3XL",
                value: "3.75rem",
                subText: "3.75rem",
              },
              {
                label: "4XL",
                value: "4rem",
                subText: "4rem",
              },
              {
                label: "8XL",
                value: "8rem",
                subText: "8rem",
              },
              {
                label: "16XL",
                value: "16rem",
                subText: "16rem",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
            },
          },
          {
            propertyName: "fontStyle",
            label: "强调",
            controlType: "BUTTON_GROUP",
            options: [
              {
                icon: "text-bold",
                value: "BOLD",
              },
              {
                icon: "text-italic",
                value: "ITALIC",
              },
            ],
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
      {
        sectionName: "两侧淡入淡出",
        children: [
          {
            propertyName: "gradient",
            helpText: "是否显示跑马灯两侧的淡入淡出效果",
            label: "显示淡入淡出",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "gradientColor",
            label: "淡入淡出颜色",
            controlType: "COLOR_PICKER",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.TEXT,
              params: {
                regex: /^(?![<|{{]).+/,
              },
            },
          },
          {
            propertyName: "gradientWidth",
            helpText: "跑马灯淡入淡出效果宽度",
            label: "效果宽度（px）",
            controlType: "NUMERIC_INPUT",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            min: 0,
            validation: {
              type: ValidationTypes.NUMBER,
              params: {
                natural: true,
              },
            },
          },
        ],
      },
    ];
  }

  onCycleComplete = () => {
    this.props.onCycleComplete &&
      super.executeAction({
        triggerPropertyName: "onCycleComplete",
        dynamicString: this.props.onCycleComplete,
        event: {
          type: EventType.ON_CYCLE_COMPLETE,
        },
      });
  };

  onFinish = () => {
    this.props.onFinish &&
      super.executeAction({
        triggerPropertyName: "onFinish",
        dynamicString: this.props.onFinish,
        event: {
          type: EventType.ON_MARQUEE_FINISH,
        },
      });
  };

  getWidgetView() {
    return (
      <MarqueeComponent
        {...this.props}
        onCycleCompleteCallback={this.onCycleComplete}
        onFinishCallback={this.onFinish}
      />
    );
  }
}

export interface MarqueeWidgetProps extends WidgetProps, MarqueeComponentProps {
  onCycleComplete: string;
  onFinish: string;
}

export default MarqueeWidget;
