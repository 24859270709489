import React from "react";
import type { BaseWidgetProps } from "widgets/BaseWidgetHOC/withBaseWidgetHOC";
import styled, { css } from "styled-components";

const HiddenDetachWidgetWrapper = styled.div<{
  isVisible: boolean;
}>`
  ${(props) =>
    !props.isVisible
      ? css`
          && div[type="CANVAS_WIDGET"] > * {
            opacity: 0.6;
          }
        `
      : ""};
`;

const TaroHiddenWrapper = ({
  type,
  detachFromLayout,
  children,
  isVisible,
}: BaseWidgetProps) => {
  if (!detachFromLayout) {
    return children;
  }

  // add opacity css when TARO_BOTTOM_BAR_WIDGET is hidden
  if (type === "TARO_BOTTOM_BAR_WIDGET") {
    return (
      <HiddenDetachWidgetWrapper isVisible={isVisible ?? true}>
        {children}
      </HiddenDetachWidgetWrapper>
    );
  }

  return children;
};

export default TaroHiddenWrapper;
