import React from "react";
import BaseControl, { ControlProps } from "../BaseControl";
import styled from "styled-components";
import { generateReactKey } from "utils/generators";
import { DraggableListControl } from "pages/Editor/PropertyPane/DraggableListControl";
import { DraggableListCard } from "components/propertyControls/DraggableListCard";
import { getNextEntityName } from "utils/AppsmithUtils";
import _ from "lodash";
import orderBy from "lodash/orderBy";
import { Button } from "design-system";

const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type State = {
  focusedIndex: number | null;
};

class FieldControl extends BaseControl<ControlProps, State> {
  typeInfo = {
    name: "字段",
    id: "field",
    initProps: {
      fieldType: "input",
      inputType: "text",
      required: true,
    },
  };

  constructor(props: ControlProps) {
    super(props);

    this.state = {
      focusedIndex: null,
    };
  }

  componentDidUpdate(prevProps: ControlProps): void {
    //on adding a new column last column should get focused
    if (
      prevProps.propertyValue &&
      this.props.propertyValue &&
      Object.keys(prevProps.propertyValue).length + 1 ===
        Object.keys(this.props.propertyValue).length
    ) {
      this.updateFocus(Object.keys(this.props.propertyValue).length - 1, true);
    }
  }

  updateFocus = (index: number, isFocused: boolean) => {
    this.setState({ focusedIndex: isFocused ? index : null });
  };

  updateItems = (items: Array<Record<string, any>>) => {
    const fieldsObj = items.reduce((obj: any, each: any, index: number) => {
      obj[each.id] = {
        ...each,
        index,
      };
      return obj;
    }, {});
    this.updateProperty(this.props.propertyName, fieldsObj);
  };

  onEdit = (index: number) => {
    const fields = this.getItems();
    const cellToChange = fields[index];
    this.props.openNextPanel({
      index,
      ...cellToChange,
      propPaneId: this.props.widgetProperties.widgetId,
    });
  };

  getItems = () => {
    const fields: Array<{
      id: string;
      label: string;
      isDisabled: boolean;
      isVisible: boolean;
      widgetId: string;
    }> = _.isString(this.props.propertyValue)
      ? []
      : Object.values(this.props.propertyValue);
    return orderBy(fields, ["index"], ["asc"]);
  };

  render() {
    return (
      <TabsWrapper>
        <DraggableListControl
          deleteOption={this.deleteOption}
          itemHeight={45}
          fixedHeight={370}
          focusedIndex={this.state.focusedIndex}
          items={this.getItems()}
          onEdit={this.onEdit}
          propertyPath={this.props.dataTreePath}
          renderComponent={(props: any) =>
            DraggableListCard({
              ...props,
              isDelete: true,
              placeholder: "标题",
            })
          }
          toggleVisibility={this.toggleVisibility}
          updateItems={this.updateItems}
          updateOption={this.updateOption}
          updateFocus={this.updateFocus}
        />
        <Button
          className="self-end"
          kind="tertiary"
          onClick={this.addOption}
          startIcon="plus"
        >
          添加{this.typeInfo.name}
        </Button>
      </TabsWrapper>
    );
  }

  toggleVisibility = (index: number) => {
    const fields = this.getItems();
    const isVisible = fields[index].isVisible === true ? false : true;
    const updatedCells = fields.map((cell, cellIndex) => {
      if (index === cellIndex) {
        return {
          ...cell,
          isVisible: isVisible,
        };
      }
      return cell;
    });
    this.updateProperty(this.props.propertyName, updatedCells);
  };

  deleteOption = (index: number) => {
    const fieldsArray = this.getItems();
    const itemId = fieldsArray[index].id;
    if (fieldsArray && fieldsArray.length === 1) return;
    const updatedArray = fieldsArray.filter((eachItem: any, i: number) => {
      return i !== index;
    });
    const updatedObj = updatedArray.reduce(
      (obj: any, each: any, index: number) => {
        obj[each.id] = {
          ...each,
          index,
        };
        return obj;
      },
      {},
    );
    this.deleteProperties([`${this.props.propertyName}.${itemId}.isVisible`]);
    this.updateProperty(this.props.propertyName, updatedObj);
  };

  updateOption = (index: number, updatedLabel: string) => {
    const fieldsArray = this.getItems();
    const itemId = fieldsArray[index].id;
    this.updateProperty(
      `${this.props.propertyName}.${itemId}.label`,
      updatedLabel,
    );
  };

  addOption = () => {
    let fields = this.props.propertyValue;
    const fieldsArray = this.getItems();
    const newTabId = generateReactKey({ prefix: this.typeInfo.id });
    const newTabLabel = getNextEntityName(
      this.typeInfo.name,
      fieldsArray.map((tab: any) => tab.label),
    );
    fields = {
      ...fields,
      [newTabId]: {
        id: newTabId,
        index: fieldsArray.length,
        label: newTabLabel,
        widgetId: generateReactKey(),
        name: newTabId,
        ...this.typeInfo.initProps,
      },
    };

    this.updateProperty(this.props.propertyName, fields);
  };

  static getControlType() {
    return "FIELDS_INPUT";
  }
}

export default FieldControl;
