import { ValidationTypes } from "constants/WidgetValidation";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import { ICON_NAMES } from "widgets/MenuButtonWidget/constants";
import {
  booleanForEachRowValidation,
  colorForEachRowValidation,
  iconNamesForEachRowValidation,
  iconPositionForEachRowValidation,
  textForEachRowValidation,
} from "widgets/TableWidgetV2/widget/propertyUtils";
import { getSourceDataAndCaluclateKeysForEventAutoComplete } from "widgets/TableWidgetV2/widget/utilities";

export default {
  editableTitle: false,
  titlePropertyName: "label",
  panelIdPropertyName: "id",
  contentChildren: [
    {
      sectionName: "通用",
      children: [
        {
          propertyName: "label",
          helpText: "使用 {{currentItem}} 绑定来设置菜单项标签",
          label: "标签",
          controlType: "MENU_BUTTON_DYNAMIC_ITEMS",
          placeholderText: "{{currentItem.name}}",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              expected: {
                type: "Array of values",
                example: `['option1', 'option2'] | [{ "label": "label1", "value": "value1" }]`,
                autocompleteDataType: AutocompleteDataType.ARRAY,
              },
              fnString: textForEachRowValidation.toString(),
            },
          },
          evaluatedDependencies: ["primaryColumns"],
        },
        {
          propertyName: "isVisible",
          helpText: "控制组件的显示/隐藏，可以使用 {{currentItem}} 来绑定",
          label: "是否显示",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              fnString: booleanForEachRowValidation.toString(),
            },
          },
          customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
          evaluatedDependencies: ["primaryColumns"],
        },
        {
          propertyName: "isDisabled",
          helpText: "禁止组件输入，可以使用 {{currentItem}} 来绑定",
          label: "禁用",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              fnString: booleanForEachRowValidation.toString(),
            },
          },
          customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
          evaluatedDependencies: ["primaryColumns"],
        },
      ],
    },
    {
      sectionName: "事件",
      children: [
        {
          helpText: "点击菜单项时，可以使用 {{currentItem}} 来绑定",
          propertyName: "onClick",
          label: "onClick",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
          additionalAutoComplete:
            getSourceDataAndCaluclateKeysForEventAutoComplete,
          evaluatedDependencies: ["primaryColumns"],
        },
      ],
    },
  ],
  styleChildren: [
    {
      sectionName: "图标",
      children: [
        {
          propertyName: "iconName",
          label: "选择图标",
          helpText: "选择菜单项图标，可以使用 {{currentItem}} 来绑定",
          controlType: "ICON_SELECT",
          isBindProperty: true,
          isTriggerProperty: false,
          isJSConvertible: true,
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              allowedValues: ICON_NAMES,
              fnString: iconNamesForEachRowValidation.toString(),
            },
          },
          customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
          evaluatedDependencies: ["primaryColumns"],
        },
        {
          propertyName: "iconAlign",
          label: "图标位置",
          helpText: "设置菜单项图标位置，可以使用 {{currentItem}} 来绑定",
          controlType: "ICON_TABS",
          defaultValue: "left",
          fullWidth: false,
          options: [
            {
              startIcon: "skip-left-line",
              value: "left",
            },
            {
              startIcon: "skip-right-line",
              value: "right",
            },
          ],
          isBindProperty: true,
          isTriggerProperty: false,
          isJSConvertible: true,
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              allowedValues: ["center", "left", "right"],
              fnString: iconPositionForEachRowValidation.toString(),
            },
          },
          customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
          evaluatedDependencies: ["primaryColumns"],
        },
      ],
    },
    {
      sectionName: "颜色",
      children: [
        {
          propertyName: "iconColor",
          helpText: "设置菜单项图标颜色，可以使用 {{currentItem}} 来绑定",
          label: "图标颜色",
          controlType: "COLOR_PICKER",
          isBindProperty: true,
          isTriggerProperty: false,
          isJSConvertible: true,
          customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
          evaluatedDependencies: ["primaryColumns"],
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              regex: /^(?![<|{{]).+/,
              fnString: colorForEachRowValidation.toString(),
            },
          },
        },
        {
          propertyName: "backgroundColor",
          helpText: "设置菜单项背景颜色，可以使用 {{currentItem}} 来绑定",
          label: "背景颜色",
          controlType: "COLOR_PICKER",
          isBindProperty: true,
          isTriggerProperty: false,
          isJSConvertible: true,
          customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
          evaluatedDependencies: ["primaryColumns"],
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              regex: /^(?![<|{{]).+/,
              fnString: colorForEachRowValidation.toString(),
            },
          },
        },
        {
          propertyName: "textColor",
          helpText: "设置菜单项文本颜色，可以使用 {{currentItem}} 来绑定",
          label: "文本颜色",
          controlType: "COLOR_PICKER",
          isBindProperty: true,
          isTriggerProperty: false,
          isJSConvertible: true,
          customJSControl: "MENU_BUTTON_DYNAMIC_ITEMS",
          evaluatedDependencies: ["primaryColumns"],
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              regex: /^(?![<|{{]).+/,
              fnString: colorForEachRowValidation.toString(),
            },
          },
        },
      ],
    },
  ],
};
