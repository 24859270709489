import React from "react";
import BaseWidget, { WidgetProps, WidgetState } from "widgets/BaseWidget";
import { ValidationTypes } from "constants/WidgetValidation";
import BottomBarComponent from "../component";
import { generateClassName } from "utils/generators";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";

export class MBottomBarWidget extends BaseWidget<
  MBottomBarWidgetProps,
  WidgetState
> {
  static type = "TARO_BOTTOM_BAR_WIDGET";

  static getConfig() {
    return {
      name: "底部面板",
      tags: [WIDGET_TAGS.LAYOUT],
      searchTags: ["bottom bar"],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: true,
      isMobile: true,
    };
  }

  static getDefaults() {
    return {
      widgetName: "bottom_bar",
      rows: 12,
      columns: 64,
      height: 100,
      detachFromLayout: true,
      children: [],
      version: 1,
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: false,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              hackOverflowHidden: true,
              children: [],
              version: 1,
            },
          },
        ],
      },
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc": "底部面板",
      "!url": "https://taroify.gitee.io/taroify.com/introduce/",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "height",
            label: "面板高度（不带单位的数字）",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
              params: { min: 80, max: 200 },
            },
          },
          {
            propertyName: "isVisible",
            label: "是否可见",
            helpText: "控制显示/隐藏",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ];
  }

  getWidgetView() {
    return (
      <BottomBarComponent
        className={`${generateClassName(this.props.widgetId)}`}
        // height={this.props.height}
        // widgetId={this.props.widgetId}
        childrenProps={this.props.children || []}
        {...this.props}
      />
    );
  }
}

export interface MBottomBarWidgetProps extends WidgetProps {
  children?: WidgetProps[];
  height: number;
}

export default MBottomBarWidget;
