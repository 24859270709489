import React, { ReactNode } from "react";
import BaseWidget, { WidgetProps, WidgetState } from "widgets/BaseWidget";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import ModalComponent from "../component";
import type { RenderMode } from "constants/WidgetConstants";
import { generateClassName } from "utils/generators";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";

export class MPopupWidget extends BaseWidget<MPopupWidgetProps, WidgetState> {
  static type = "TARO_POPUP_WIDGET";

  static getConfig() {
    return {
      name: "底部弹窗",
      searchTags: ["popup", "dialog", "modal"],
      iconSVG: IconSVG,
      needsMeta: true,
      isCanvas: false,
      isMobile: true,
      tags: [WIDGET_TAGS.LAYOUT],
    };
  }

  static getDefaults() {
    return {
      widgetName: "popup",
      rows: 40,
      columns: 64,
      detachFromLayout: true,
      canOutsideClickClose: true,
      rounded: true,
      height: 400,
      children: [],
      version: 1,
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: false,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              children: [],
              version: 1,
            },
          },
        ],
      },
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc": "底部弹窗",
      "!url": "https://taroify.gitee.io/taroify.com/introduce/",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "属性",
        children: [
          {
            propertyName: "height",
            label: "弹窗高度（不带单位的数字）",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.NUMBER,
              params: { min: 200, max: 800 },
            },
          },
          {
            propertyName: "canOutsideClickClose",
            label: "点击背景关闭",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
      {
        sectionName: "动作",
        children: [
          {
            helpText: "弹窗关闭后",
            propertyName: "onClose",
            label: "onClose",
            controlType: "ACTION_SELECTOR",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "风格",
        children: [
          {
            propertyName: "rounded",
            label: "圆角风格",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
          },
        ],
      },
    ];
  }

  onModalClose = () => {
    if (this.props.onClose) {
      super.executeAction({
        triggerPropertyName: "onClose",
        dynamicString: this.props.onClose,
        event: {
          type: EventType.ON_MODAL_CLOSE,
        },
      });
    }
  };

  // closed by popup self
  closeModal = () => {
    this.props.updateWidgetMetaProperty("isVisible", false);
  };

  getWidgetView() {
    return (
      <ModalComponent
        canOutsideClickClose={!!this.props.canOutsideClickClose}
        className={`${generateClassName(this.props.widgetId)}`}
        // height={this.props.height}
        rounded={this.props.rounded}
        isOpen={!!this.props.isVisible}
        onModalClose={this.onModalClose}
        childrenProps={this.props.children || []}
        {...this.props}
        onClose={this.closeModal}
      />
    );
  }
}

export interface MPopupWidgetProps extends WidgetProps {
  renderMode: RenderMode;
  children?: WidgetProps[];
  canOutsideClickClose?: boolean;
  rounded?: boolean;
  height: number;
  onClose: string;
}

export default MPopupWidget;
