import React from "react";
import FieldControl from "./FieldControl";

class CellControl extends FieldControl {
  typeInfo: any = {
    name: "单元格",
    id: "cell",
    initProps: {
      picType: "none",
      showArrow: true,
      isVisible: true,
    },
  };

  static getControlType() {
    return "CELLS_INPUT";
  }
}

export default CellControl;
