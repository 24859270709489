import React from "react";
import FieldControl from "./FieldControl";

class ActionControl extends FieldControl {
  typeInfo: any = {
    name: "动作",
    id: "action",
    initProps: {
      type: "icon",
      icon: "like-o",
      buttonType: "warning",
    },
  };

  static getControlType() {
    return "ACTIONS_INPUT";
  }
}

export default ActionControl;
