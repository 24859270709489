import React from "react";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import { DEFAULT_FONT_SIZE, FontStyleTypes } from "constants/WidgetConstants";
import { fontSizeUtility } from "widgets/WidgetUtils";
import { Text } from "@blueprintjs/core";
import tinycolor from "tinycolor2";

const StyledFont = styled.div<{
  fontFamily?: string;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  font-family: ${(props) => props.fontFamily};
`;

const StyledText = styled(Text)<{
  textColor?: string;
  fontStyle?: string;
  fontSize?: string;
}>`
  color: ${(props) => props?.textColor};
  font-style: ${(props) =>
    props?.fontStyle?.includes(FontStyleTypes.ITALIC) ? "italic" : ""};
  text-decoration: ${(props) =>
    props?.fontStyle?.includes(FontStyleTypes.UNDERLINE) ? "underline" : ""};
  font-weight: ${(props) =>
    props?.fontStyle?.includes(FontStyleTypes.BOLD) ? "bold" : "normal"};
  font-size: ${({ fontSize }) =>
    fontSizeUtility(fontSize) || DEFAULT_FONT_SIZE};
`;

function MarqueeComponent(props: MarqueeComponentProps) {
  const {
    text,
    fontSize,
    fontFamily,
    textColor,
    backgroundColor,
    fontStyle,
    gradientColor,
    onCycleCompleteCallback,
    onFinishCallback,
    ...others
  } = props;
  const textProps = {
    fontSize,
    textColor,
    fontStyle,
  };
  const realFontFamily =
    fontFamily !== "System Default" ? fontFamily : undefined;
  let siderColor: [number, number, number] | undefined;
  if (gradientColor) {
    const colorObj = new tinycolor(gradientColor).toRgb();
    siderColor = [colorObj.r, colorObj.g, colorObj.b];
  }
  return (
    <Marquee
      {...others}
      style={backgroundColor ? { background: backgroundColor } : undefined}
      gradientColor={siderColor}
      onCycleComplete={onCycleCompleteCallback}
      onFinish={onFinishCallback}
    >
      <StyledFont fontFamily={realFontFamily}>
        <StyledText {...textProps}>{text || "请配置跑马灯文字"}</StyledText>
      </StyledFont>
    </Marquee>
  );
}

export interface MarqueeComponentProps {
  text: string;
  play?: boolean;
  direction?: "left" | "right";
  speed?: number;
  delay?: number;
  loop?: number;
  pauseOnHover?: boolean;
  pauseOnClick?: boolean;
  onCycleCompleteCallback?: () => void;
  onFinishCallback?: () => void;
  fontSize?: string;
  fontFamily: string;
  textColor?: string;
  backgroundColor?: string;
  fontStyle?: string;
  gradient?: boolean;
  gradientColor?: string;
  gradientWidth?: number;
}

export default MarqueeComponent;
