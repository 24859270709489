import React from "react";
import styled from "styled-components";
import type { WidgetProps } from "widgets/BaseWidget";
import { generateClassName } from "utils/generators";
import { useSelector } from "react-redux";
import { Backdrop, Loading } from "@taroify/core";
import { View } from "@tarojs/components";
import { previewModeSelector } from "selectors/editorSelectors";

import { ClickContentToOpenPropPane } from "utils/hooks/useClickToSelectWidget";
import { WidgetNameLayer } from "layoutSystems/common/widgetName/WidgetNameLayer";
import { EVAL_ERROR_PATH } from "utils/DynamicBindingUtils";
import { get } from "lodash";

const Container = styled(View)`
  position: fixed;
  top: 60px;
  width: 0;
  height: 0;
`;

const BackdropBox = styled(Backdrop)`
  left: unset;
  right: unset;
  top: 35px;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  --backdrop-background-color: transparent;
`;

const LoadingContainer = styled(View)`
  width: 150px;
  height: 150px;
  padding: 12px;
  border-radius: 6px;
  background: #000000bd;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  --loading-color: #fff;
  --loading-text-color: #fff;
`;

const Indicator = styled(View)`
  position: absolute;
  left: -64px;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  top: 4px;
  background: #bdbdbd66;
  border: 2px dashed #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & .taroify-loading {
    color: #7e7e7e;

    & .taroify-loading__spinner {
      animation: none;
    }
  }
`;

export interface LoadingComponentProps extends WidgetProps {
  showLoading?: boolean;
  loadingText?: string;
}

/* eslint-disable react/display-name */
export function LoadingComponent(props: LoadingComponentProps) {
  const isPreviewMode = useSelector(previewModeSelector);

  const { showLoading, loadingText, isLoading, renderMode } = props;
  const loadingComponent = (
    <BackdropBox open={showLoading || isLoading}>
      <LoadingContainer>
        <Loading direction="vertical">{loadingText || "加载中..."}</Loading>
      </LoadingContainer>
    </BackdropBox>
  );

  if (renderMode === "CANVAS") {
    return (
      <Container className={generateClassName(props.widgetId)}>
        <WidgetNameLayer
          componentWidth={60}
          detachFromLayout
          disablePropertyPane={false}
          evalErrorsObj={get(props, EVAL_ERROR_PATH, {})}
          parentId={props.parentId}
          showControls
          topRow={props.topRow}
          type={props.type}
          widgetId={props.widgetId}
          widgetName={props.widgetName}
        >
          <ClickContentToOpenPropPane widgetId={props.widgetId}>
            {loadingComponent}
            {isPreviewMode ? null : (
              <Indicator>
                <Loading type="spinner" />
              </Indicator>
            )}
          </ClickContentToOpenPropPane>
        </WidgetNameLayer>
      </Container>
    );
  }
  return loadingComponent;
}

export default LoadingComponent;
