// JSON Form

export const JSON_FORM_CONNECT_OVERLAY_TEXT = () =>
  "通过数据源或者静态 JSON 数据生成表单";
export const JSON_FORM_CONNECT_BUTTON_TEXT = () => "生成表单";
export const SUCCESSFULL_BINDING_MESSAGE = (action: string) =>
  `${action}成功！刷新表格查看更新`;
export const ONSUBMIT_NOT_CONFIGURED_MESSAGE = (widgetName: string) =>
  `onSubmit 事件响应没有在组件 ${widgetName} 中配置`;
export const ONSUBMIT_NOT_CONFIGURED_ACTION_TEXT = () => "查看更多";

export const NO_CONNECTABLE_WIDGET_FOUND = () =>
  "请添加表格或列表组件来展示数据";

export const ONSUBMIT_NOT_CONFIGURED_ACTION_URL = () =>
  "https://docs.appsmith.com/build-apps/how-to-guides/submit-form-data";
