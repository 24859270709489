import React from "react";
import BaseWidget, { WidgetProps, WidgetState } from "widgets/BaseWidget";
import { ScrollView, RichText } from "@tarojs/components";
import { ValidationTypes } from "constants/WidgetValidation";
import styled from "styled-components";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";

const Container = styled(ScrollView)`
  width: 100%;
  height: 100%;
  & img {
    width: 100%;
  }
  & p {
    margin: 0;
  }
`;

class MHtmlWidget extends BaseWidget<MHtmlWidgetProps, WidgetState> {
  static type = "TARO_HTML_WIDGET";

  static getConfig() {
    return {
      name: "HTML",
      searchTags: ["html"],
      iconSVG: IconSVG,
      needsMeta: false,
      isCanvas: false,
      isMobile: true,
      tags: [WIDGET_TAGS.DISPLAY],
    };
  }

  static getDefaults() {
    return {
      widgetName: "html",
      rows: 24,
      columns: 56,
      content:
        "<p style='font-size: 36px; font-weight: bold; font-family: fangsong; background:red; color:black; text-align: center;'>恭喜发财 大吉大利</p>",
      version: 1,
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      "!doc": "HTML",
      "!url": "https://taroify.gitee.io/taroify.com/introduce/",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      content: "string",
    };
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "数据",
        children: [
          {
            propertyName: "content",
            label: "HTML内容",
            controlType: "INPUT_TEXT",
            placeholderText: "输入HTML格式内容",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.TEXT },
          },
        ],
      },
    ];
  }

  getWidgetView() {
    const { content } = this.props;
    return (
      <Container scrollY>
        <RichText nodes={content} />
      </Container>
    );
  }
}

export interface MHtmlWidgetProps extends WidgetProps {
  content: string;
}

export default MHtmlWidget;
